@import 'libs/vars';

.incident {
    margin-bottom: 2em;

    h4 {
        line-height: 3em;
        padding-left: 2em;
    }

    &.down {
        border: 3px solid red;
        h4 {
            background-color: red;
            color: _palette(bg);
        }
    }
    &.investigating, &.scheduled {
        border: 3px solid _palette(accent3);
        h4 {
            background-color: _palette(accent3);
            color: _palette(fg);
        }
    }
    &.resolved {
        border: 3px solid _palette(lt-blue);
        h4 {
            background-color: _palette(lt-blue);
            color: _palette(bg);
        }
    }

    p {
        padding: 0 2em;
    }

}
