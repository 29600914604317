///
/// Hyperbolic by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Solutions */

	.solutions {
		margin: 1em 0 _size(element-margin) 0;
        @include vendor('display', 'flex');
        @include vendor('flex-wrap', 'wrap');

        .solution {
            width: 100%;
            margin: 3em 0;
            @include vendor('display', 'flex');
            @include vendor('flex-direction', row);
            align-items: center;
            .solution-image {
                min-width: 50%;
                min-height: 350px;
                background: url('../images/solution-bg.png') no-repeat #04091f;
                background-position: -120px 0px;
                transition: background-position 0.25s ease;
                box-shadow: 0 1rem 3rem 0 rgba(0,0,0,0.25);
                border-radius: 15px;
                text-align: center;
                margin-right: 4em;
                img {
                    background-color: #fff;
                    border-radius: 50%;
                    margin: 5em;
                }
                h3 {
                    margin-top: -1.4em;
                    margin-bottom: 1.5em;
                    color: _palette(bg);
                    font-size: 2.5rem;
                    text-transform: unset;
                }
            }
            .solution-text {
                ul {
                    text-decoration: dashed;
                    list-style-type: none;
                    padding: 1em 0 0 0;

                    li {
                        background: url(../images/blue-checkmark.png) no-repeat transparent;
                        background-position: 0 6px;
                        margin-bottom: 0.5em;
                        padding-left: 1.8em;
                        vertical-align: middle;
                        font-size: medium;
                    }
                }
                a {
                    min-width: unset;
                    font-size: unset;
                }
            }
        }

		.solution:nth-child(even) {
			flex-direction: row-reverse;
            .solution-image {
                margin-right: 0;
                margin-left: 4em;
            }
		}

		@include breakpoint('<=large') {


		}

		@include breakpoint('<=medium') {
            .solution {
                @include vendor('flex-wrap', 'wrap');
                justify-content: center;
                margin: 1em;
                padding: 0 1em;
                .solution-image {
                    background-position: unset;
                    transition: background-position 0.25s ease;
                    width: 100%;
                    margin: 0;
                    min-height: unset;
                }
                .solution-text {
                    width: 100%;
                    margin: 0;
                    padding: 2em;
                }
            }
            .solution:nth-child(even) {
                flex-direction: row;
                .solution-image {
                    margin: 0;
                }

            }

		}

		@include breakpoint('<=small') {

		}
		.center {
			display: flex;
			justify-content: center;
			img {
				max-width: 33%;
			}
		}
	}
