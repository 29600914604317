///
/// Hyperbolic by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', (
			'background-color #{_duration(transition)} ease-in-out',
			'color #{_duration(transition)} ease-in-out',
			'transform #{_duration(transition) * 0.5} ease-in-out'
		));
		border-radius: _size(border-radius);
		border: 0;
		cursor: pointer;
		display: inline-block;
		font-size: 1.5rem;
		font-family: _font(family-title);
		font-weight: _font(weight-bold);
		height: _size(element-height);
		line-height: _size(element-height);
		padding: 0 1rem;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;

		&.small {
			font-size: 0.8rem;
			height: _size(element-height) * 0.75;
			line-height: _size(element-height) * 0.75;
			padding: 0 1.75rem;
		}

		&.large {
			font-size: 1.25rem;
			height: _size(element-height) * 1.25;
			line-height: _size(element-height) * 1.25;
			padding: 0 3rem;
		}

		&.wide {
			min-width: 13rem;
		}

		&.icon {
			&:before {
				margin-right: 0.5rem;
			}
		}

		&.fit {
			width: 100%;
		}

		&.disabled,
		&:disabled {
			@include vendor('pointer-events', 'none');
			opacity: 0.25;
		}
	}

	@mixin color-button($p: null) {
		input[type="submit"],
		input[type="reset"],
		input[type="button"],
		button,
		.button {
			background-color: transparent;
			//box-shadow: inset 0 0 0 1px _palette($p, fg-bold);
			color: _palette($p, fg-bold) !important;

			@if ($p == 'invert') {
				&:hover {
					background-color: transparentize(_palette($p, fg-bold), 0.9);

					&:active {
						background-color: transparentize(_palette($p, fg-bold), 0.75);
					}
				}
			}
			@else {
				&:hover {
					//box-shadow: inset 0 0 0 1px _palette(accent1);
					color: _palette(accent1) !important;

					&:active {
						background-color: transparentize(_palette(accent1), 0.75);
					}
				}
			}

			&.primary {
				//@include gradient-background;
				background-color: _palette(accent1);
				//box-shadow: 0 0.5rem 1.75rem 0 rgba(0,0,0,0.25);
				color: _palette(invert, fg-bold) !important;

				&:hover {
					background-color: lighten(_palette(accent1), 8);

					&:active {
						background-color: desaturate(darken(_palette(accent1), 8), 5);
					}
				}
			}
		}
	}

	@include color-button;