/** Quadfold  **/

#quadfold {
    background-color: _palette(bg);
    margin: -19px auto;
    padding-top: 0;
    padding-bottom: 0;

    ul {
        display: flex;
        flex-flow: wrap;
        list-style: none;
        padding: 0;

        li{
            width: calc(100% / 4);
            display: inline;
            text-align: center;
            padding: 4.25em 1.25em 1.25em 1.25em;

            i::before{
                font-size: 52pt;
            }

            a{
                display: block;
                border-bottom: none;
                text-decoration: none;

                h3{
                    padding-top: 0;
                    font-size: 12pt;
                    font-weight: bold;
                    font-family: _font(family-title);
                }

                p {
                    margin-top: 1em;
                    color: _palette(drk-blue);
                    font-size: 12pt;
                    line-height: normal;
                    padding: 0 1.5em;
                }
            }
        }

        li:hover {
            background-color: #d1e5f2;
            transition: all 0.5s ease;
        }
    }

    @include breakpoint('<=xlarge') {
        margin: -12px auto;
        ul {
            li {
                padding: 4em 0 0 0;
            }
        }
    }
    @include breakpoint('<=medium') {
        margin: -12px auto;
        ul {
            li {
                width: 50%
            }
        }
    }

    @include breakpoint('<=small') {
        ul {
            li {
                width: 100%
            }
        }
    }

}