///
/// Hyperbolic by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Form */

	form {
		margin: 0 0 _size(element-margin) 0;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	input[type="search"],
	input[type="url"],
	select,
	textarea {
		@include vendor('appearance', 'none');
		background-color: transparent;
		border-radius: _size(border-radius);
		border: none;
		border: solid 1px;
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 1.25rem;
		text-decoration: none;
		width: 100%;

		&:invalid {
			box-shadow: none;
		}
	}

	label {
		display: block;
		font-size: 1rem;
		font-weight: _font(weight-bold);
		margin: 0 0 (_size(element-margin) * 0.5) 0;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	input[type="search"],
	input[type="url"] {
		height: _size(element-height);
	}

	select {
		background-size: 1.25rem;
		background-repeat: no-repeat;
		background-position: calc(100% - 1rem) center;
		height: _size(element-height);
		padding-right: _size(element-height);
		text-overflow: ellipsis;

		&:focus {
			&::-ms-value {
				background-color: transparent;
			}
		}

		&::-ms-expand {
			display: none;
		}
	}

	textarea {
		padding: 0.75rem 1rem;
	}

	input[type="checkbox"],
	input[type="radio"], {
		@include vendor('appearance', 'none');
		display: block;
		float: left;
		margin-right: -2rem;
		opacity: 0;
		width: 1rem;
		z-index: -1;

		& + label {
			@include icon(false, solid);
			cursor: pointer;
			display: inline-block;
			font-size: 1rem;
			font-weight: _font(weight);
			padding-left: (_size(element-height) * 0.6) + 0.875rem;
			padding-right: 0.875rem;
			position: relative;

			&:before {
				border-radius: _size(border-radius);
				border: solid 1px;
				content: '';
				display: inline-block;
				font-size: 0.85rem;
				height: (_size(element-height) * 0.6);
				left: 0;
				line-height: (_size(element-height) * 0.575);
				position: absolute;
				text-align: center;
				top: (_size(element-height) * -0.05);
				width: (_size(element-height) * 0.6);
			}
		}

		&:checked + label {
			&:before {
				content: '\f00c';
			}
		}
	}

	input[type="checkbox"] {
		& + label {
			&:before {
				border-radius: _size(border-radius);
			}
		}
	}

	input[type="radio"] {
		& + label {
			&:before {
				border-radius: 100%;
			}
		}
	}

	::-webkit-input-placeholder {
		opacity: 1.0;
	}

	:-moz-placeholder {
		opacity: 1.0;
	}

	::-moz-placeholder {
		opacity: 1.0;
	}

	:-ms-input-placeholder {
		opacity: 1.0;
	}

	@mixin color-form($p: null) {
		label {
			color: _palette($p, fg-bold);
		}

		input[type="text"],
		input[type="password"],
		input[type="email"],
		input[type="tel"],
		input[type="search"],
		input[type="url"],
		select,
		textarea {
			border-color: _palette($p, border);

			&:focus {
				@if $p == 'invert' {
					border-color: _palette($p, fg-bold);
					box-shadow: 0 0 0 1px _palette($p, fg-bold);
				}
				@else {
					border-color: _palette(accent1);
					box-shadow: 0 0 0 1px _palette(accent1);
				}
			}
		}

		select {
			background-image: svg-url("<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'><path d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='#{_palette($p, border)}' /></svg>");

			option {
				color: _palette($p, fg);
				background-color: _palette($p, bg);
			}
		}

		input[type="checkbox"],
		input[type="radio"], {
			& + label {
				color: _palette($p, fg);

				&:before {
					border-color: _palette($p, border);
				}
			}

			&:checked + label {
				&:before {
					@if $p == 'invert' {
						background-color: _palette($p, fg-bold);
						border-color: _palette($p, fg-bold);
						color: _palette(accent1);
					}
					@else {
						@include gradient-background;
						border-color: _palette(accent1);
						color: _palette(invert, fg-bold);
					}
				}
			}

			&:focus + label {
				&:before {
					@if $p == 'invert' {
						border-color: _palette($p, fg-bold);
						box-shadow: 0 0 0 1px _palette($p, fg-bold);
					}
					@else {
						border-color: _palette(accent1);
						box-shadow: 0 0 0 1px _palette(accent1);
					}
				}
			}
		}

		::-webkit-input-placeholder {
			color: _palette($p, fg-light) !important;
		}

		:-moz-placeholder {
			color: _palette($p, fg-light) !important;
		}

		::-moz-placeholder {
			color: _palette($p, fg-light) !important;
		}

		:-ms-input-placeholder {
			color: _palette($p, fg-light) !important;
		}
	}

	@include color-form;