///
/// Hyperbolic by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Banner */

	#banner {
		$wrapper-pad: _size(wrapper-pad, default);

		@include color-button(invert);
		@include color-typography(invert);
		//@include curve-after;
		@include padding($wrapper-pad * 1.25, 0);
		@include vendor('align-items', 'center');
		@include vendor('display', 'flex');
		@include vendor('justify-content', 'center');
		background: rgba(70, 152, 202, 0);
		min-height: calc(75vh + #{_size(element-height) * 2});
		position: relative;
		text-align: center;

		&:before {
			@include vendor('pointer-events', 'none');
			@include vendor('transition', 'opacity 2s ease-in-out 0.75s');
			background: _palette(bg-alt);
			content: '';
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		> .content {
			@include vendor('transition', (
				'transform 1s ease-in-out',
				'opacity 1.25s ease-in-out'
			));
			position: relative;
			z-index: 1;
			margin-top: -6.2em;

			#pri-menu{
				background-color: transparent;
				text-align: center;
				display: block;
				margin-bottom: 6em;


				ul {
					display: flex;
					flex-direction: row;
					list-style: none;
					text-decoration: none;
					justify-content: center;
					align-items: bottom;
					padding-left: 0;


					li {
						font-family: _font(family-title);
						font-weight: bold;
						//min-width: 200px;
						a {
							padding-bottom: 1em;
							border: none;
							text-transform: uppercase;
							letter-spacing: 3px;
						}

						a:hover {
							border-bottom: 2px solid _palette(bg);
							color: _palette(lt-blue)
						}
						padding: 0 1em;
					}
					.primary-menu-item {
						flex-grow: 2;
						flex-shrink: 2;
					}
					span.primary-menu-item {
						flex-grow: 1;
						flex-shrink: 1;
					}
				}
			}

			.logo {
				margin-top: -3em;

				img {
					width: 290px
				}
			}

			> :last-child {
				margin-bottom: 0;
			}

			h1 {
				font-family: _font(family-title);
				font-weight: _font(weight-bold);
			}

			h2 {
				font-family: _font(family-title);
				max-width: 960px;
			}
		}

		body.is-preload & {
			&:before {
				opacity: 1;
			}

			> .content {
				@include vendor('transform', 'translateY(0.75rem)');
				opacity: 0;
			}
		}

		@include breakpoint('<=large') {
			$wrapper-pad: _size(wrapper-pad, large);

			@include padding($wrapper-pad * 1.25, 0);
			background-attachment: scroll, scroll;

			> .content{
				margin-top: -11em;
				.logo {
					margin-top: -3rem;
					margin-bottom: 6rem;

					img {
						width: 230px;
					}
				}
			}
		}

		@include breakpoint('<=medium') {
			$wrapper-pad: _size(wrapper-pad, large);

			@include padding($wrapper-pad * 1.25, $wrapper-pad);

			> .content {
				margin-top: -11em;
				#pri-menu {
					margin-bottom: 0;

					.logo {
						margin-bottom: 0;
					}
					li {
						display: none;
					}
				}
			}

		}

		@include breakpoint('<=small') {
			$wrapper-pad: _size(wrapper-pad, small);

			@include padding($wrapper-pad * 2, $wrapper-pad * 0.5);
			min-height: 0;
			> .content {
				margin-top: -12em;
				#pri-menu {
					margin-bottom: 0;

					.logo {
						margin-top: 0;
					}
				}
			}


		}

		@include breakpoint('<=xsmall') {
			$wrapper-pad: _size(wrapper-pad, small);

			@include padding($wrapper-pad * 2.5, $wrapper-pad * 0.5);
		}

		@include breakpoint('<=xsmall') {
			br {
				display: none;
			}
		}
	}