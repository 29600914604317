///
/// Hyperbolic by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Banner */

	#primary-menu-container {
		$wrapper-pad: _size(wrapper-pad, default);

		@include color-button(invert);
		@include color-typography(invert);
		//@include curve-after;
		@include vendor('align-items', 'center');
		@include vendor('display', 'flex');
		@include vendor('justify-content', 'center');
		background: rgba(70, 152, 202, 0);
		position: relative;
		text-align: center;
		margin-top: -3.5em;
		padding-top: 3.5em;

		&:before {
			@include vendor('pointer-events', 'none');
			@include vendor('transition', 'opacity 2s ease-in-out 0.75s');
			background: _palette(bg-alt);
			content: '';
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		> .content {
			@include vendor('transition', (
				'transform 1s ease-in-out',
				'opacity 1.25s ease-in-out'
			));
			position: relative;
			z-index: 1;

			#pri-menu{
				background-color: transparent;
				text-align: center;
				display: block;

				ul {
					display: flex;
					flex-direction: row;
					list-style: none;
					text-decoration: none;
					justify-content: space-between;
					align-items: bottom;
					padding-left: 0;

					span {
						div {
							margin-top: -3em;
						}
						a {
						border: none;
						}
						a:hover {
							border-bottom: 2px solid _palette(bg);
							color: _palette(lt-blue)
						}
						min-width: 240px;
					
					}
					
					li {
						font-family: _font(family-title);
						font-weight: bold;
						a {
							padding-bottom: 1em;
							border: none;
							text-transform: uppercase;
							letter-spacing: 3px;
						}

						a:hover {
							border-bottom: 2px solid _palette(bg);
							color: _palette(lt-blue)
						}
						padding: 0 1em;
						min-width: 200px;

						> a.img-logo img {
							margin-top: -3em;
						}
					}


				}
			}

			> :last-child {
				margin-bottom: 0;
			}

			h1 {
				font-family: _font(family-title);
				font-weight: _font(weight-bold);
			}

			h2 {
				font-family: _font(family-title);
				max-width: 960px;
			}
		}

		body.is-preload & {
			&:before {
				opacity: 1;
			}

			> .content {
				@include vendor('transform', 'translateY(0.75rem)');
				opacity: 0;
			}
		}

		@include breakpoint('<=large') {
			$wrapper-pad: _size(wrapper-pad, large);

			@include padding($wrapper-pad * 1.25, 0);
			background-attachment: scroll, scroll;

			> .content{
				.logo {
					margin-top: -3rem;

					img {
						width: 230px;
					}
				}
			}
		}

		@include breakpoint('<=medium') {
			$wrapper-pad: _size(wrapper-pad, large);

			@include padding($wrapper-pad * 1.25, $wrapper-pad);

			> .content {
				#pri-menu {
					margin-bottom: 0;

					.logo {
						margin-bottom: 0;
					}
					li {
						display: none;
					}
				}
			}

		}

		@include breakpoint('<=small') {
			$wrapper-pad: _size(wrapper-pad, small);

			@include padding($wrapper-pad * 2, $wrapper-pad * 0.5);
			min-height: 0;
			> .content {
				#pri-menu {
					margin-bottom: 0;

					.logo {
						margin-top: 0;
					}
				}
			}


		}

		@include breakpoint('<=xsmall') {
			$wrapper-pad: _size(wrapper-pad, small);

			@include padding($wrapper-pad * 2.5, $wrapper-pad * 0.5);
		}

		@include breakpoint('<=xsmall') {
			br {
				display: none;
			}
		}
	}