///
/// Hyperbolic by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Spotlights */

	.spotlight {
		ul.actions.special {
			margin-top: 2em;
			padding-left: 0;
		}
	}


	.spotlights {
		margin: 1em 0 _size(element-margin) 0;

		header h2 {
			text-align: center;
		}

		section {
			@include vendor('align-items', 'center');
			@include vendor('display', 'flex');
			@include vendor('flex-direction', row);

			.image {
				@include vendor('flex-grow', '0');
				@include vendor('flex-shrink', '0');

				border-radius: 100%;
				box-shadow: 0 1rem 3rem 0 rgba(0,0,0,0.25);
				width: 15rem;
				text-align: center;

				img {
					width: 100%;
					border-radius: 75%;
					display: block;
				}
			}

			.content {
				header {
					h3 {
						margin-top: 0;
						margin-bottom: 0.5em;
					}
					&.major {
						h3 {
							text-align: center;
							&:after {
								margin-right: auto;
							}
						}
					}
				}
				margin-top: 2em;
				padding-left: 2em;
				padding-right: 2em;
			}

			&:first-child {
				margin-top: 0;
			}

		}

		section:nth-child(2n) {
			@include vendor('flex-direction', row-reverse);
		}

		@include breakpoint('<=large') {
			section {
				margin: 0 0 2em 0;
				border-bottom: 1px solid rgba(0,0,0,.125);
				padding-bottom: 2em;
				.image {
					margin: 0;
					width: 20rem;
				}
				.content {
					margin: 0;
				}
			}
		}

		@include breakpoint('<=medium') {
			section {
				display: block;
				text-align: center;

				.image {
					margin: 0 0 1rem 0;
					max-width: 100%;
				}

				.content {
					header {
						&.major {
							h3 {
								&:after {
									margin-left: auto;
									margin-right: auto;
								}
							}
						}
					}
				}

			}
		}

		@include breakpoint('<=small') {
			section {
				.image {
					width: 15rem;
					margin: 0 0 2rem 0;
				}
			}
		}
		.center {
			display: flex;
			justify-content: center;
			img {
				max-width: 33%;
			}
		}
	}