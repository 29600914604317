///
/// Hyperbolic by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Features */

	.features {
		@include vendor('align-items', 'center');
		@include vendor('display', 'flex');
		margin: 0 0 _size(element-margin) 0;

		> ul {
			margin-bottom: 0;
			padding-left: 0;

			> li {
				list-style: none;
				margin-top: 3rem;

				> :last-child {
					margin-bottom: 0;
				}

				&:first-child {
					margin-top: 0;
				}
			}

			&:first-child {
				margin-right: 4rem;
				text-align: right;
			}

			&:last-child {
				margin-left: 4rem;
				text-align: left;
			}

			h3 {
				font-size: 1.25rem;
				margin: 0 0 (_size(element-margin) * 0.375) 0;
			}
		}

		> .image {
			@include vendor('flex-grow', '0');
			@include vendor('flex-shrink', '0');

			img {
				border-radius: 100%;
				box-shadow: 0 1rem 3rem 0 rgba(0,0,0,0.25);
				display: block;
				width: 30rem;
			}
		}

		@include breakpoint('<=large') {
			@include vendor('flex-wrap', 'wrap');
			@include vendor('justify-content', 'center');

			> .image {
				@include vendor('order', '-1');
				margin: 0 0 3rem 0;
				width: 100%;

				> img {
					margin: 0 auto;
					width: 20rem;
				}
			}

			> ul {
				@include vendor('flex-grow', '1');
				@include vendor('flex-shrink', '1');
				padding: 0 3rem;
				width: 50%;

				&:first-child {
					margin-right: 0;
					text-align: center;
				}

				&:last-child {
					margin-left: 0;
					text-align: center;
				}
			}
		}

		@include breakpoint('<=medium') {
			> .image {
				img {
					max-width: 100%;
				}
			}

			> ul {
				padding: 0 1.5rem;
			}
		}

		@include breakpoint('<=small') {
			> .image {
				img {
					width: 15rem;
				}
			}
		}

		@include breakpoint('<=xsmall') {
			> .image {
				img {
					display: none;
				}
			}
			
			> ul {
				padding: 0;
				width: 100%;

				> li {
					margin-top: 2rem;

					&:first-child {
						margin-top: 2rem;
					}
				}

				&:first-child {
					> li {
						&:first-child {
							margin-top: 0;
						}
					}
				}
			}
		}
	}