.connectors-wrapper{
    display: flex;
    flex-flow: wrap;
    justify-content: center;


    .connector {
        text-align: center;
        width: calc(100% / 3);
        display: block;
        padding: 2em;

        a {
            text-decoration: none;
            border: none;
            width: 100%;
        }
        a:hover {
            //color: #fff;
            text-decoration: underline;
            //background-color: _palette(accent1);
        }

        img {
            width: 100%;
        }

        p {
            width: 100%;
            background-color: inherit;
        }

        @include breakpoint('<=medium') {
            width: calc(100% / 2);
            margin: 0 0 1em 0;
            padding: 2em;
        }

        @include breakpoint('<=small') {
            width: 100%;
            padding: 1.5em;
        }
    }

}
