///
/// Hyperbolic by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Wrapper */

	.front-page .wrapper .inner {
		width: 75rem;
	}


	.wrapper {
		$wrapper-pad: _size(wrapper-pad, default);

		@include padding($wrapper-pad, 0);
		position: relative;

		> .inner {
			margin: 0 auto;
			width: 50rem;
			max-width: calc(100% - #{$wrapper-pad});

			.default-page{
				min-height: 30em;
			}

			video {
				width: 100%;
			}
		}

		&.style1 {
			background-color: _palette(bg);

			h3 {
				margin-top: 3rem;
				margin-bottom: 1rem;
			}
			p {
				margin-bottom: 1rem;
			}
			p + ul {
				margin-top: -1rem;
				margin-bottom: 0;
				padding-left: 3rem;
			}
		}

		&.style2 {
			@include color(invert);


			&.alt {
				//@include curve-after;
				//@include curve-before;
			}
		}

		&.style3{
			margin-top: -2em;
			background-color: _palette(lt-gray);
			span p{
				max-width: 700px;
			}
		}

		@include breakpoint('<=large') {
			$wrapper-pad: _size(wrapper-pad, large);

			@include padding($wrapper-pad, 0);

			> .inner {
				max-width: calc(100% - #{$wrapper-pad});
			}
		}

		@include breakpoint('<=small') {
			$wrapper-pad: _size(wrapper-pad, small);

			@include padding($wrapper-pad, 0);

			> .inner {
				max-width: calc(100% - #{$wrapper-pad});
			}
		}
	}