///
/// Hyperbolic by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Icons */

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;

		li {
			display: inline-block;
			padding: 0 0.75rem 0 0;

			&:last-child {
				padding-right: 0;
			}

			.icon {

				&.alt {
					margin: 0 0.375rem;
				}
			}
		}

		@include breakpoint('<=large') {
			li {
				.icon {
					&.alt {
						margin: 0 0.25rem;
					}
				}
			}
		}

		@include breakpoint('<=small') {
			li {
				.icon {
					&.alt {
						margin: 0;
					}
				}
			}
		}
	}