///
/// Hyperbolic by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Footer */

	#footer {
		$wrapper-pad: _size(wrapper-pad, default);

		@include color-typography(invert);
		@include color-icon(invert);
		//@include curve-before-alt;
		@include padding($wrapper-pad, 0);
		background-attachment: fixed, fixed;
		background-image: linear-gradient(90deg, #{transparentize(_palette(invert, bg), 0.4)}, #{transparentize(_palette(invert, bg), 0.4)}), url('../images/hex.png');
		background-position: center, center;
		background-repeat: repeat, no-repeat;
		background-size: auto, cover;
		position: relative;
		text-align: center;

		@include breakpoint('<=large') {
			$wrapper-pad: _size(wrapper-pad, large);

			@include padding($wrapper-pad, 0);
			background-attachment: scroll, scroll;
		}

		@include breakpoint('<=small') {
			$wrapper-pad: _size(wrapper-pad, small);

			@include padding($wrapper-pad, 0);
		}
	}

	#footer2 {
		$wrapper-pad: _size(wrapper-pad, default);
		@include vendor('align-items', 'center');
		@include vendor('display', 'flex');
		@include vendor('justify-content', 'space-between');
		flex-flow: wrap;
		@include color-typography();
		@include color-icon(invert);
		background-color: _palette(bg);



		div {
			width: 33%;
			text-align: center;

			&.logo img{
				margin-top: 0.5em;
			}

			p {
				text-align: center;
				margin: 0;
				font-size: 10pt;
				line-height: normal;

			}
		}

		@include breakpoint('<=small') {
			$wrapper-pad: _size(wrapper-pad, small);
			@include padding($wrapper-pad, 0);
			padding-top: 1em;
			div {
				width: 100%;
				margin: 0;
			}
		}

	}