/// Styling for the contact page.
///
///
///
///

.alert {
    padding: 1em;
    background-color: _palette(accent3);
}

.contact-form {
    display: flex;
    flex-wrap: wrap;

    section {
        width: 50%;
        padding: 1em;
        text-align: center;
    }

    .contact-info {
        display: flex;
        flex-wrap: wrap;
        div {
            width: 100%;
        }
    }


    @include breakpoint('<=large') {

        section {
            width: 100%;
        }
        .contact-info div {
            width: 33%;
        }
    }


    @include breakpoint('<medium') {
        .contact-info div {
            width: 100%;
        }
    }
}
